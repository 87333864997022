/**
 * @typedef {Object} ThirdParty
 * @property {string} token type of Ad Third Party Config
 * @property {string} [script] static script source
 */

/**
  * @type {Object<string, ThirdParty>}
  */
// token property here is equal to the type property from the configs;
export const THIRD_PARTIES = {
  MISC_CUST_PARAMS: {
    token: 'miscCustParams',
  },
  WFXTG: {
    token: 'wfxtg',
  },
  AMAZON_HEADER_BIDDER: {
    token: 'amznHdBid',
    script: '//c.amazon-adsystem.com/aax2/apstag.js',
    // script: '//c.aps.amazon-adsystem.com/apstag.js', // Future?: ASR script
  },
  AMAZON_SLOTS: {
    token: 'amznslots',
    script: '//c.amazon-adsystem.com/aax2/amzn_ads.js',
  },
  PREBID: {
    token: 'prebid',
    script: '//ep00.epimg.net/js/pbs/prebid.js',
  },
  CRITEO_SLOT: {
    token: 'criteoSlot',
    script: '//static.criteo.net/js/ld/publishertag.js',
  },
  CRITEO: {
    token: 'criteo',
  },
  IAS_PET_SLOT: {
    token: 'iasPetSlot',
    script: '//cdn.adsafeprotected.com/iasPET.1.js',
  },
  INDEX_EXCHANGE: {
    token: 'index',
  },
  LOTAME: {
    token: 'lotame',
  },
  WX_AD_TARGETING: {
    token: 'wxAdTargeting',
  },
  GEOEDGE: {
    token: 'geoedge',
    script: '//d3b02estmut877.cloudfront.net/grumi-ip.js',
  },
  CONFIANT: {
    token: 'confiant',
    script: '//cdn.confiant-integrations.net/sM1wMdWIAB1LeJwC9QvIgGUpPQ0/gpt_and_prebid/config.js',
  },
  PREMIUM_AD_DATA_LAKE: {
    token: 'premiumAdDataLake',
  },
  REFRESH_ADS: {
    token: 'refreshAds',
  },
  MOBILE_REFRESH_ADS: {
    token: 'mobileRefreshAds',
  },
  RUBICON: {
    token: 'rubicon',
  },
  VIDEO_PREROLL_BIDDERS: {
    token: 'videoPrerollBidders',
  },
  USER_ACTION_AD_REFRESH: {
    token: 'userActionAdRefresh',
  },
  DESKTOP_USER_ACTION_AD_REFRESH: {
    token: 'desktopUserActionAdRefresh',
  },
  SEQUENTIAL_AD_LOADER: {
    token: 'sequentialAdLoader',
  },
  BIDDING_PROCESS_TIMEOUT: {
    token: 'biddingProcessTimeout',
    maxTime: -1,
  },
  AB_TESTING: {
    token: 'abTesting',
  },
  HELIOS_AD_SDK: {
    token: 'helios',
  },
  JANUS: {
    token: 'janus',
  },
  TIME_FRAME: {
    token: 'timeframeKeyVal',
  },
  ADMIRAL: {
    token: 'admiral',
  },
  IAB_CATEGORIES: {
    token: 'iabc',
  },
  INSTANA: {
    token: 'instana',
  },
  ASSERTIVE_YIELD: {
    token: 'assertiveYield',
  },
};

export const DEVICE_CLASSES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

// This is a allowList of all GDPR restricted
// PII cust_param keys
export const PII_CUST_PARAM_KEYS = [
  'cc',
  'cnty',
  'countryName',
  'ct',
  'dma',
  'lat',
  'loc',
  'lon',
  'placeId',
  'rmid',
  'st',
  'zip',
];

// This is list of cust_params adTech wants removed
export const AUDIENCE_CAPTIVATION_PROPS_2_REMOVE = [
  'atf',
  'browser',
  'baro',
  'cat',
  'cobr',
  'cs',
  'd1',
  'd2',
  'd3',
  'd4',
  'd5',
  'excl',
  'faud',
  'fbeb',
  'fc',
  'fc1',
  'fc2',
  'fc3',
  'fgeo',
  'fhi',
  'fhic',
  'fhr',
  'fli',
  'flo',
  'floc',
  'flr',
  'fltmp',
  'fltmpc',
  'fpdi1',
  'fpdi2',
  'fpdi3',
  'fsnw',
  'ftl',
  'h2o',
  'hmid',
  'id',
  'impression_id',
  'intl',
  'iom',
  'ipm',
  'ipmid',
  'kw',
  'layer',
  'lo',
  'loc',
  'native',
  'os',
  'pip',
  'player',
  'plin',
  'snw',
  'spla',
  'story',
  'template',
  'tile',
  'tmp',
  'tmpr',
  'twch',
  'urlref',
  'uv',
  'vid',
  'videocat',
  'wfxcampaigntype',
  'wind',
];

export const RESTRICTED_PREBIDDING_PRELOAD_SCRIPTS = [
  'criteo',
  'criteoSlot',
  'iasPet',
  'amznslots',
  'amznHdBid',
  'rubicon',
];

// Flag the presence of these key-values in Instana meta data
export const INSTANA_PAGE_KVS = new Set([
  'wfxtg',
  'cxtg',
  'zcs',
  'nzcs',
  'fpd',
  'cnd',
]);
