import { createSelector } from 'reselect';
import { registrationEnabledSelector } from '@wxu/contexts/src/registration/selectors';
import {
  subscriptionTierSelector,
  entitlementFilter,
  isPremiumSubscriptionTier,
  isWuPremiumSubscriptionTier,
  isValidSubscriptionTier,
  isWuValidSubscriptionTier,
  subscriptionsEnabledSelector,
} from '@wxu/contexts/src/subscription/selectors';
import {
  experienceQuerySelector,
} from '@wxu/contexts/src/page/selectors';
import {
  REDUCER_KEY,
  USER_ACCOUNT_KEY,
  USER_ACCOUNT_ID_KEY,
  USER_ACCOUNT_FIRSTNAME_KEY,
  USER_ACCOUNT_EMAIL_KEY,
  USER_ACCOUNT_HASPASSWORD_KEY,
  USER_ACCOUNT_RESPONSE_KEY,
} from './constants';

export const userAccountSelector = state => state?.[REDUCER_KEY]?.[USER_ACCOUNT_KEY] ?? {};
export const userAccountResponseSelector = state => state?.[REDUCER_KEY]?.[USER_ACCOUNT_RESPONSE_KEY] ?? {};

export const userAccountIdSelector = createSelector(
  userAccountSelector,
  userAccount => userAccount[USER_ACCOUNT_ID_KEY] ?? '',
);

export const userAccountEmailSelector = createSelector(
  userAccountSelector,
  userAccount => userAccount[USER_ACCOUNT_EMAIL_KEY] ?? '',
);

export const userAccountFirstNameSelector = createSelector(
  userAccountSelector,
  userAccount => userAccount[USER_ACCOUNT_FIRSTNAME_KEY] ?? '',
);

export const userAccountHasPasswordSelector = createSelector(
  userAccountSelector,
  userAccount => userAccount[USER_ACCOUNT_HASPASSWORD_KEY] ?? '',
);

export const userAccountDisplayNameSelector = createSelector(
  userAccountFirstNameSelector,
  userAccountEmailSelector,
  (userFirstName, userEmail) => userFirstName ?? userEmail,
);

export const userAccountInitialSelector = createSelector(userAccountDisplayNameSelector, (userFirstName) => {
  const userInitial = userFirstName ? userFirstName.charAt(0).toUpperCase() : '';

  return userInitial;
});


export const userSubscriptionTierSelector = createSelector(
  subscriptionTierSelector,
  (tier) => tier
);

export const tierFeatureSelector = createSelector(
  subscriptionTierSelector,
  subscriptionsEnabledSelector,
  (tier, subsEnabled) => {
    if (!subsEnabled) return false;

    return entitlementFilter(tier);
  }
);

export const isPremiumUserSelector = createSelector(
  userSubscriptionTierSelector,
  subscriptionsEnabledSelector,
  experienceQuerySelector,
  (tier, subsEnabled, experience) => {
    if (!subsEnabled) return false;

    if (experience === 'wu') return isWuPremiumSubscriptionTier(tier);

    return isPremiumSubscriptionTier(tier);
  }
);

export const isUserLoggedInSelector = createSelector(
  userSubscriptionTierSelector,
  registrationEnabledSelector,
  experienceQuerySelector,
  (tier, registrationEnabled, experience) => {
    if (!registrationEnabled) return false;

    if (experience === 'wu') return isWuValidSubscriptionTier(tier);

    const isValidTier = isValidSubscriptionTier(tier);

    return isValidTier;
  }
);

export const isLoggedInPremiumUserSelector = createSelector(
  isUserLoggedInSelector,
  isPremiumUserSelector,
  (loggedIn, isPremium) => {
    if (loggedIn && isPremium) return true;

    return false;
  }
);

export const isLoggedInStandardUserSelector = createSelector(
  isUserLoggedInSelector,
  isPremiumUserSelector,
  (loggedIn, isPremium) => {
    if (loggedIn && !isPremium) return true;

    return false;
  }
);
