import { uuid } from '@wxu/uuid';
// redux
export const REDUCER_KEY = 'user';
export const AD_BLOCKER_REDUCER_KEY = 'ads';
export const RECENT_LOCATIONS_KEY = 'recentLocations';
export const USER_PREFERENCE_KEY = 'userPreference';
export const DEFAULT_LOCATIONS_KEY = 'defaultLocations';
export const USER_ID_KEY = 'anonymousId';
export const UPS_USER_ID_KEY = 'userId';
export const UPS_USER_NAME_KEY = 'userName';
export const UPS_USER_EMAIL_KEY = 'userEmail';
export const UPS_EMAIL = 'USER_EMAIL';
export const IS_LOCATION_CHANGED = 'isLocationChanged';
export const SELECTED_LOCATION_TYPE = 'selectedLocationType';
export const USER_EMAIL = 'userEmail';
export const SOURCE_MODULE_KEY = 'sourceModule';
export const SOURCE_MODULE_POSITION_KEY = 'sourceModulePosition';
export const USER_HAS_LOGGED_IN_BEFORE = 'userHasLoggedInBefore';

export const INITIAL_STATE = {
  [IS_LOCATION_CHANGED]: false,
  [SELECTED_LOCATION_TYPE]: '',
  [RECENT_LOCATIONS_KEY]: [],
  [USER_PREFERENCE_KEY]: {},
  [USER_ID_KEY]: uuid(),
  [UPS_USER_ID_KEY]: '',
  [UPS_USER_NAME_KEY]: '',
  [UPS_USER_EMAIL_KEY]: '',
};

// visited pages
export const VISITED_PAGES = 'visitedPages';
export const UPDATE_SETTING = 'TWC/USER/UPDATE_SETTING/PERSIST';
export const UPDATE_VISITED_PAGES = 'TWC/USER/UPDATE_VISITED_PAGES/PERSIST';

export const UPDATE_SOURCE_MODULE = 'TWC/USER/UPDATE_SOURCE_MODULE/PERSIST';

// Action types
// Append `/PERSIST` to the end of an action type to indicate that type should trigger a sync with localStorage.
// e.g. 'WXU/USER/SET_RECENT_LOCATIONS/PERSIST'
export const ADD_RECENT_LOCATION = 'WXU/USER/ADD_RECENT_LOCATION';
export const SET_RECENT_LOCATIONS = 'WXU/USER/SET_RECENT_LOCATIONS/PERSIST';
export const SET_USER_PREFERENCE = 'WXU/USER/SET_USER_PREFERENCE/PERSIST';
export const SET_DEFAULT_LOCATIONS = 'WXU/USER/SET_DEFAULT_LOCATIONS';
export const CLEAR_RECENT_LOCATIONS = 'WXU/USER/CLEAR_RECENT_LOCATIONS';
export const REMOVE_LOCATION = 'WXU/USER/REMOVE_LOCATION';
export const SET_FAVORITE_LOCATION = 'WXU/USER/SET_FAVORITE_LOCATION';
export const UNSET_FAVORITE_LOCATION = 'WXU/USER/UNSET_FAVORITE_LOCATION';
export const SWITCH_UNITS = 'WXU/USER/SWITCH_UNITS';
export const SET_USER_ID = 'WXU/USER/SET_USER_ID/PERSIST';
export const SET_UPS_USER_INFO_ACTION = 'WXU/USER/SET_UPS_USER_INFO/PERSIST';
export const FETCH_LOCATIONS_DONE = 'WXU/USER/FETCH_LOCATIONS_DONE';
export const LOAD_NEXT_LOCATION = 'WXU/DAL/LOAD_NEXT_LOCATION';
export const LOAD_ALL_LOCATIONS = 'WXU/DAL/LOAD_ALL_LOCATIONS';
export const SET_USER_STATE = 'WXU/USER/SET_USER_STATE/PERSIST';
export const SET_IS_LOCATION_CHANGED = 'WXU/USER/SET_IS_LOCATION_CHANGED/PERSIST';
export const SET_SELECTED_LOCATION_TYPE = 'WXU/USER/SET_SELECTED_LOCATION_TYPE/PERSIST';
export const SET_USER_HAS_LOGIN = 'WXU/USER/SET_USER_HAS_LOGIN';

// Used to explicitly persist user data outside of @wxu/web-storage persist flow
export const PERSIST_USER_DATA = 'WXU/PERSIST_USER_DATA';
export const USER_PERSIST_KEY = REDUCER_KEY;

// NERF-related constants
export const NERF_USER_PERSIST_KEY = 'user';
export const NERF_USER_ID_KEY = 'anonId';
export const NERF_UPS_USER_ID_KEY = 'userId';
export const NERF_RECENT_LOCATIONS_PATH = ['settings', 'searchedLocations'];
export const NERF_VISITED_PAGES_PATH = ['settings', 'visitedPages'];
export const NERF_PERSIST_USER_DATA = 'WXU/NERF_PERSIST_USER_DATA';

// User Subscription Constants
export const SET_USER_PROFILE = 'WXU/USER/SET_PROFILE/PERSIST';
export const SUBSCRIPTION_RESYNC_REQUEST = 'WXU/USER/SUBSCRIPTION_RESYNC_REQUEST';
export const USER_PROFILE_KEY = 'profile';

export const SET_CAN_USE_NEWRELIC = 'WXU/SET_CAN_USER_NEWRELIC';
export const CAN_USE_NEWRELIC_KEY = 'canUseNewrelic';

export const SUBSCRIPTION_TIER_NONE = 'none';
export const SUBSCRIPTION_TIER_ONE = 'tier1';
export const TIER_NAME_OVERRIDES = {
  webNoAds: SUBSCRIPTION_TIER_ONE,
};

// User Session Constants
export const SESSION_DURATION = 30; // minutes
export const SESSIONS_DAYS_IN_MONTH = 30; // days
export const SESSIONS_DAYS_IN_WEEK = 7; // days
export const LS_USER_LAST_SEEN = 'userLastSeenTime';
export const LS_USER_SESSION_VAL = 'userSession';

// Authentication Constants
export const USER_AUTHENTICATED = 'WXU/USER/AUTHENTICATED';
export const AUTH_ID_TOKEN_KEY = 'id_token';
export const AUTH_REFRESH_TOKEN_KEY = 'refresh_token';

export const UPS_USER_LOGGED_IN = 'WXU/USER/UPS/LOGGED_IN';
export const LOGIN_SUCCESS_ACTION = 'WXU/USER/UPS/LOGIN_SUCCESS';
export const UPS_USER_LOGGED_OUT = 'WXU/USER/UPS/LOGGED_OUT';
export const ANY_SUCCESS_LOGIN = 'ANY_SUCCESS_LOGIN';
export const AUTH_SUCCESS_ACTION = 'WXU/UPS2/USER_AUTHENTICATED';
export const USER_ACCOUNT = 'WXU/UPS2/USER_ACCOUNT';
export const RESET_PASSWORD_SUCCESS_ACTION = 'WXU/UPS2/PASSWORD_RESET';
export const CHANGE_PASSWORD_SUCCESS_ACTION = 'WXU/MEMBER_PASSWORD_CHANGED';
export const CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD = 'WXU/UPS2/USER_CHANGE_PASSWORD';
export const SET_UPS_EMAIL_ACTION = 'WXU/UPS2/USER_EMAIL';
export const UPDATE_UPSX_CONSENT = 'WXU/UPSX/UPDATE_UPSX_CONSENT';

// Pattern Constants
export const STRONG_PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W])/;
export const TRIM_SPACE_PASSWORD_PATTERN = /^$|^[^\s]+(\s+[^\s]+)*$/;
export const BETWEEN_8_AND_64_CHARACTERS_PATTERN = /^.{8,64}$/;
export const CONTAIN_AT_LEAST_ONE_NUMBER_PATTERN = /.*[0-9].*/;
export const CONTAIN_UPPER_AND_LOWER_CASE_LETTERS_PATTERN = /(?=.*[a-z])(?=.*[A-Z])/;
export const CONTAIN_AT_LEAST_ONE_SPECIAL_CHARACTER_PATTERN = /.*?[#?.,!@$%^&'[\]*-]/;
export const MATCH_EMOJIS_PATTERN = /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g;
export const AMPERSAND_HASHTAG_PATTERN = /^((?!&#).)*$/;
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// TODO: Fix some of these action types, do not follow the pattern (WXU/CONTEXT/ACTION_NAME)
export const CHANGE_PASSWORD_RESPONSE_ACTION = 'CHANGE_PASSWORD_RESPONSE_ACTION';
export const CHANGE_EMAIL_RESPONSE_ACTION = 'CHANGE_EMAIL_RESPONSE_ACTION';
export const CHANGE_PASSWORD_LOGIN_RESPONSE_ACTION = 'CHANGE_PASSWORD_LOGIN_RESPONSE_ACTION';
export const CHANGE_EMAIL_LOGIN_RESPONSE_ACTION = 'CHANGE_EMAIL_LOGIN_RESPONSE_ACTION';
export const CURRENT_EMAIL_ACTION = 'CURRENT_EMAIL_ACTION';
export const IS_LOADING_ACTION = 'IS_LOADING_ACTION';
export const SET_USER_ACCOUNT_ACTION = 'WXU/UPSX/SET_USER_ACCOUNT';
export const MEMBER_CHANGED_PASSWORD_ACTION = 'WXU/UPS2/MEMBER_PASSWORD_CHANGED';
export const CLOSE_CHANGE_PASSWORD_MODAL = 'CLOSE_CHANGE_PASSWORD_MODAL';

// short-list of user state to persist
export const PERSIST_ALLOWED_KEYS = [
  RECENT_LOCATIONS_KEY,
  USER_PREFERENCE_KEY,
  USER_ID_KEY,
  UPS_USER_ID_KEY,
  UPS_USER_NAME_KEY,
  UPS_USER_EMAIL_KEY,
  VISITED_PAGES,
  IS_LOCATION_CHANGED,
  SELECTED_LOCATION_TYPE,
  SOURCE_MODULE_KEY,
  SOURCE_MODULE_POSITION_KEY,
  USER_HAS_LOGGED_IN_BEFORE,
];

export const SET_USER_SESSIONS_COUNT_ACTION = 'WXU/USER/SET_USER_SESSIONS_COUNT_ACTION';
export const SET_USER_SESSIONS_COUNT_PER_WEEK_ACTION = 'WXU/USER/SET_USER_SESSIONS_COUNT_PER_WEEK_ACTION';

export const USER_SESSIONS_COUNT = 'userSessionCount';
export const USER_SESSIONS_COUNT_PER_WEEK = 'userSessionCountPerWeek';

export const MURMUR2_HASH_SEED = 894157739;

export const PARTNERS_SYNC = 'PARTNERS_SYNC';

export const SIGNUP_PAGE_IMAGE_KEY = 'signUpImage';
export const SIGNUP_PAGE_IMAGES = [
  {
    name: 'signup-1.jpg',
    alt: 'fatherDaughter',
  },
  {
    name: 'signup-2_0.jpg',
    alt: 'manRunningInRain',
  },
  {
    name: 'signup-4_0.jpeg',
    alt: 'manMountain',
  },
];

// localstorage keys
export const PREFERRED_LOCATION_PERSIST_KEY = 'preferredLocation';
export const PREFERRED_LOCATION_PERSIST_ALLOWED_KEYS = ['countryCode', 'postalCode', 'placeId'];
export const MPARTICLE_USER_PERSIST_KEY = 'mparticleUser';
export const GROWTHBOOK_USER_DATA = 'growthbookExperiment';
