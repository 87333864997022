export const REDUCER_KEY = 'user-account';

export const USER_SUBSCRIPTION_REDUCER_KEY = 'user-subscription';

export const USER_ACCOUNT_RESPONSE_KEY = 'userAccountResponse';
export const USER_ACCOUNT_KEY = 'userAccount';
export const USER_ACCOUNT_ID_KEY = 'userID';
export const USER_ACCOUNT_FIRSTNAME_KEY = 'firstName';
export const USER_ACCOUNT_EMAIL_KEY = 'email';
export const USER_ACCOUNT_HASPASSWORD_KEY = 'hasPassword';

export const SET_USER_ACCOUNT_ACTION = 'WXU/UPSX/SET_USER_ACCOUNT/PERSIST';
export const SET_USER_ACCOUNT_RESPONSE_ACTION = 'WXU/UPSX/SET_USER_ACCOUNT_RESPONSE';

export const LOGOUT_UPS_USER = 'WXU/USER/UPS/LOGOUT';
export const LOGIN_REDIRECT_PAGE = 'LOGIN_REDIRECT_PAGE';
export const CHECK_USER_LOGGED_IN = 'CHECK/USER/LOGGED/IN';
export const GO_TO_LOGIN_PAGE = 'WXU/USER/GO_TO_LOGIN_PAGE';

export const UPDATE_UPSX_CONSENT = 'WXU/UPSX/UPDATE_UPSX_CONSENT';

export const CLEANUP_USER_STATE_ACTION = 'WXU/USER-ACCOUNT/CLEANUP_USER_STATE';
export const VERIFY_USER_ANONID_ACTION = 'WXU/USER-ACCOUNT/VERIFY_USER_ANONID';
