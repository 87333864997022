import { createSelector } from 'reselect';
import {
  experienceQuerySelector,
  isMobileSelector,
  pageKeySelector,
} from '@wxu/contexts/src/page/selectors';
import { createDeepEqualSelector } from '@wxu/contexts/src/redux-dal/selectors';
import { CREATE_USER_ACCOUNT_RESPONSE } from './actions/userAccount';
import {
  REDUCER_KEY,
  RECENT_LOCATIONS_KEY,
  USER_PREFERENCE_KEY,
  USER_ID_KEY,
  VISITED_PAGES,
  CAN_USE_NEWRELIC_KEY,
  USER_SESSIONS_COUNT,
  USER_SESSIONS_COUNT_PER_WEEK,
  IS_LOCATION_CHANGED,
  SELECTED_LOCATION_TYPE,
  SOURCE_MODULE_KEY,
  SOURCE_MODULE_POSITION_KEY,
  USER_HAS_LOGGED_IN_BEFORE,
} from './constants';

export const userStateSelector = state => state?.[REDUCER_KEY] || {};
export const userSessionsCountSelector = state => state?.[REDUCER_KEY]?.[USER_SESSIONS_COUNT];
export const userSessionsCountPerWeekSelector = state => state?.[REDUCER_KEY]?.[USER_SESSIONS_COUNT_PER_WEEK];
export const sourceModuleSelector = state => state?.[REDUCER_KEY]?.[SOURCE_MODULE_KEY] ?? {};
export const sourceModulePositionSelector = state => state?.[REDUCER_KEY]?.[SOURCE_MODULE_POSITION_KEY] ?? {};
export const createUserAccountResponse = state => state?.[REDUCER_KEY]?.[CREATE_USER_ACCOUNT_RESPONSE] ?? {};
export const userHasLoggedInBeforeSelector = state => !!state?.[REDUCER_KEY]?.[USER_HAS_LOGGED_IN_BEFORE];

export const disableUserSagaSelector = createSelector(
  pageKeySelector,
  (pageKey) => ['checkout', 'checkoutExternal'].includes(pageKey)
);

export const userIdSelector = createSelector(
  userStateSelector,
  userState => userState?.[USER_ID_KEY] ?? '',
);

export const upsUserLoggedOutSuccessSelector = createSelector(
  userStateSelector,
  userState => userState?.upsUserLoggedOutDone ?? false,
);

export const isLocationChangedSelector = createSelector(
  userStateSelector,
  userState => userState?.[IS_LOCATION_CHANGED] ?? false,
);

export const selectedLocationTypeSelector = createSelector(
  userStateSelector,
  userState => userState?.[SELECTED_LOCATION_TYPE] ?? '',
);

export const rawRecentLocationsSelector = createDeepEqualSelector(
  userStateSelector,
  userState => userState?.[RECENT_LOCATIONS_KEY] ?? [],
);

export const userPreferenceSelector = createDeepEqualSelector(
  userStateSelector,
  userState => userState?.[USER_PREFERENCE_KEY] ?? {},
);

export const userUnitPreferenceSelector = createSelector(
  userPreferenceSelector,
  userPreference => userPreference.unit ?? '',
);

export const userDashboardPreferenceSelector = createDeepEqualSelector(
  userPreferenceSelector,
  preference => preference?.dashboard || [],
);

export const rawFavoriteLocationsSelector = createDeepEqualSelector(
  userPreferenceSelector,
  preference => preference?.locations || [],
);

export const rawFavoriteLocationsCountSelector = createSelector(
  rawFavoriteLocationsSelector,
  rawFavorites => rawFavorites?.length || 0,
);

export const visitedPagesSelector = createSelector(
  userStateSelector,
  userState => userState?.[VISITED_PAGES] ?? []
);

export const previousPagecodeSelector = createSelector(
  visitedPagesSelector,
  (visitedPages) => {
    if (Array.isArray(visitedPages) && visitedPages.length > 1) {
      return visitedPages[1] || '';
    }
    return '';
  }
);
export const canUseNewrelicSelector = createSelector(
  userStateSelector,
  (userState) => userState?.[CAN_USE_NEWRELIC_KEY] ?? false
);

export const fetchLocationsDoneSelector = createSelector(
  userStateSelector,
  userState => userState?.fetchLocationsDone ?? false,
);

export const getRegistrationSourceSelector = (source) => createSelector(
  isMobileSelector,
  experienceQuerySelector,
  (isMobile, experience) => {
    const registrationSource = (experience === 'wu' ? 'WU' : 'TWC') + (isMobile ? 'MOB' : 'WEB') + source;

    return registrationSource;
  }
);
